import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container } from '@components/global';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Footer from '@sections/Footer';
import Layout from '@common/Layout';
import NavbarExternal from '@common/NavbarExternal';


const Title = styled.div`
  h1 {
    text-align: center;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 3rem;
    color: ${props => props.theme.color.dark_primary};
  }
`;

export const AboutContent = styled.div`
  p {
    text-align: justify;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.color.dark_primary};
  }

  h3 {
    color: ${props => props.theme.color.dark_primary};
  }
`;

export const BottomImage = styled.div`
    max-height: 600px;
    max-width: 600px;
    margin:10px auto;
`;

export const HeaderImage = styled.div`
    max-height: 400px;
    max-width: 1000px;
    margin:150px auto;
`;

const AboutTemplate = ({ data: { contentfulAbout: about } }) => {
  return (
    <Layout>
      <NavbarExternal />
      <Container>
        <HeaderImage><Img fluid={about.imageLandscape.fluid} /></HeaderImage>
        <Title><h1>{about.title}</h1></Title>
        <AboutContent>{documentToReactComponents(about.content.json)}</AboutContent>
        <BottomImage><Img fluid={about.image2.fluid} /></BottomImage>
      </Container>
      <Footer isCase />
    </Layout>
  );
};

export default AboutTemplate;

AboutTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulAbout: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query AboutBySlug($slug: String!) {
    contentfulAbout(slug: { eq: $slug }) {
        id
        imageLandscape {
          fluid(maxWidth: 1000) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      image2 {
        fluid(maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      title
      preview {
        json
      }
      content {
        json
      }
    }
  }
`;
